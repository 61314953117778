import React from "react";

import { ProjectInterface } from "../pages/airtable";
import Project, { ProjectProps } from "./Project";

interface ProjectListProps {
  projects: ProjectInterface[];
  onProjectClick?: (id: string) => void;
}

const ProjectList = ({ projects, onProjectClick }: ProjectListProps) => {
  return projects.length > 0 ? (
    <ul className="flex flex-col gap-[24px]">
      {projects.map((p, i) => (
        <Project key={i} {...p} onProjectClick={onProjectClick} />
      ))}
    </ul>
  ) : (
    <div className="max-w-[200px] mx-auto pt-[56px] flex flex-col items-center justify-center">
      <div className="mb-[24px] w-[48px] h-[48px]">
        <svg
          width="49"
          height="48"
          viewBox="0 0 49 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.03829 30.4074C11.5448 25.9009 17.657 23.3691 24.0302 23.3691C30.4034 23.3691 36.5156 25.9009 41.0221 30.4074C45.5287 34.9139 48.0604 41.0261 48.0604 47.3994C48.0604 47.5587 47.9971 47.7115 47.8845 47.8242C47.7718 47.9368 47.619 48.0001 47.4597 48.0001H0.600755C0.441425 48.0001 0.288621 47.9368 0.175957 47.8242C0.0632937 47.7115 0 47.5587 0 47.3994C1.79039e-07 41.0261 2.53175 34.9139 7.03829 30.4074ZM24.0302 24.5707C17.9757 24.5707 12.1691 26.9758 7.88788 31.257C3.74847 35.3964 1.3629 40.9618 1.20941 46.7986H46.851C46.6975 40.9618 44.312 35.3964 40.1726 31.257C35.8913 26.9758 30.0848 24.5707 24.0302 24.5707Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.8968 36.8861C41.2291 36.8861 40.5763 37.0841 40.0211 37.4551C39.4659 37.8261 39.0332 38.3534 38.7776 38.9703C38.5221 39.5872 38.4552 40.2661 38.5855 40.921C38.7157 41.5759 39.0373 42.1775 39.5095 42.6497C39.9817 43.1219 40.5833 43.4434 41.2382 43.5737C41.8931 43.704 42.572 43.6371 43.1889 43.3816C43.8058 43.126 44.3331 42.6933 44.7041 42.1381C45.0751 41.5828 45.2731 40.9301 45.2731 40.2623C45.2731 39.3669 44.9174 38.5081 44.2842 37.8749C43.6511 37.2418 42.7923 36.8861 41.8968 36.8861ZM39.3536 36.4561C40.1064 35.953 40.9915 35.6846 41.8968 35.6846C43.1109 35.6846 44.2753 36.1668 45.1338 37.0253C45.9923 37.8838 46.4746 39.0482 46.4746 40.2623C46.4746 41.1677 46.2061 42.0528 45.7031 42.8056C45.2001 43.5584 44.4852 44.1451 43.6487 44.4916C42.8122 44.8381 41.8918 44.9288 41.0038 44.7521C40.1158 44.5755 39.3001 44.1395 38.6599 43.4993C38.0197 42.8591 37.5837 42.0434 37.4071 41.1554C37.2304 40.2674 37.3211 39.3469 37.6676 38.5105C38.014 37.674 38.6008 36.9591 39.3536 36.4561Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.3629 26.5711C31.7773 26.5712 31.2047 26.7445 30.7173 27.0693C30.2299 27.3941 29.8496 27.8558 29.6241 28.3964C29.3986 28.9369 29.3381 29.5321 29.4502 30.1069C29.5623 30.6818 29.842 31.2106 30.2541 31.6268C30.6661 32.043 31.1921 32.328 31.7658 32.4459C32.3395 32.5638 32.9352 32.5092 33.478 32.2892C34.0208 32.0692 34.4864 31.6935 34.816 31.2094C35.1457 30.7253 35.3248 30.1545 35.3307 29.5688C35.3346 29.1766 35.2608 28.7875 35.1134 28.4239C34.9661 28.0604 34.7481 27.7297 34.4721 27.451C34.1961 27.1722 33.8676 26.9509 33.5056 26.7999C33.1436 26.6489 32.7552 26.5711 32.3629 26.5711ZM30.051 26.0695C30.7357 25.6132 31.5401 25.3697 32.3629 25.3696C32.9139 25.3696 33.4596 25.4788 33.9682 25.691C34.4768 25.9031 34.9383 26.214 35.326 26.6056C35.7137 26.9973 36.0199 27.4618 36.2269 27.9726C36.434 28.4833 36.5377 29.0299 36.5321 29.581C36.5238 30.4037 36.2723 31.2057 35.8091 31.8857C35.3459 32.5658 34.6919 33.0936 33.9294 33.4027C33.1669 33.7118 32.33 33.7884 31.524 33.6228C30.718 33.4572 29.9791 33.0569 29.4002 32.4721C28.8213 31.8874 28.4284 31.1445 28.2709 30.3369C28.1134 29.5293 28.1984 28.6932 28.5152 27.9338C28.8319 27.1744 29.3663 26.5258 30.051 26.0695Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.21 32.4408C21.6231 32.4408 21.0493 32.6148 20.5612 32.9409C20.0732 33.267 19.6928 33.7305 19.4682 34.2728C19.2436 34.8151 19.1848 35.4118 19.2993 35.9875C19.4138 36.5632 19.6965 37.092 20.1115 37.507C20.5266 37.922 21.0554 38.2047 21.631 38.3192C22.2067 38.4337 22.8034 38.375 23.3457 38.1503C23.888 37.9257 24.3515 37.5453 24.6776 37.0573C25.0037 36.5693 25.1778 35.9955 25.1778 35.4085C25.1778 34.6214 24.8651 33.8666 24.3085 33.31C23.752 32.7534 22.9971 32.4408 22.21 32.4408ZM19.8937 31.9419C20.5793 31.4838 21.3854 31.2393 22.21 31.2393C23.3158 31.2393 24.3762 31.6785 25.1581 32.4604C25.94 33.2423 26.3793 34.3027 26.3793 35.4085C26.3793 36.2331 26.1347 37.0392 25.6766 37.7248C25.2185 38.4105 24.5673 38.9448 23.8055 39.2604C23.0437 39.5759 22.2054 39.6585 21.3966 39.4976C20.5879 39.3368 19.845 38.9397 19.2619 38.3566C18.6788 37.7735 18.2818 37.0306 18.1209 36.2219C17.96 35.4131 18.0426 34.5748 18.3581 33.813C18.6737 33.0512 19.2081 32.4 19.8937 31.9419Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.071 35.4928C6.44958 35.4928 5.84211 35.677 5.32542 36.0223C4.80874 36.3675 4.40603 36.8582 4.16822 37.4324C3.93041 38.0065 3.86819 38.6382 3.98942 39.2477C4.11066 39.8571 4.4099 40.417 4.84931 40.8564C5.28872 41.2958 5.84855 41.5951 6.45803 41.7163C7.06752 41.8375 7.69926 41.7753 8.27337 41.5375C8.84747 41.2997 9.33818 40.897 9.68343 40.3803C10.0287 39.8636 10.2129 39.2561 10.2129 38.6347C10.2129 37.8014 9.88192 37.0022 9.29269 36.413C8.70346 35.8238 7.9043 35.4928 7.071 35.4928ZM4.6579 35.0233C5.37218 34.546 6.21195 34.2913 7.071 34.2913C8.22296 34.2913 9.32772 34.7489 10.1423 35.5634C10.9568 36.378 11.4145 37.4827 11.4145 38.6347C11.4145 39.4938 11.1597 40.3335 10.6824 41.0478C10.2052 41.7621 9.52684 42.3188 8.73317 42.6476C7.93949 42.9763 7.06617 43.0623 6.22363 42.8947C5.38108 42.7271 4.60715 42.3134 3.99971 41.706C3.39227 41.0986 2.97859 40.3246 2.811 39.4821C2.64341 38.6395 2.72942 37.7662 3.05817 36.9726C3.38691 36.1789 3.94361 35.5005 4.6579 35.0233Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.898 41.5243C16.3985 41.5233 15.91 41.6706 15.4944 41.9475C15.0787 42.2244 14.7545 42.6184 14.563 43.0796C14.3714 43.5409 14.321 44.0486 14.4182 44.5385C14.5154 45.0284 14.7558 45.4784 15.109 45.8316C15.4622 46.1848 15.9122 46.4252 16.4021 46.5224C16.892 46.6196 17.3997 46.5692 17.8609 46.3776C18.3222 46.186 18.7162 45.8619 18.9931 45.4462C19.27 45.0305 19.4173 44.542 19.4163 44.0426L20.0171 44.0414H19.4163C19.4163 43.3738 19.1511 42.7336 18.6791 42.2615C18.207 41.7895 17.5667 41.5243 16.8991 41.5243L16.898 41.5243ZM20.6178 44.0409C20.6191 44.7779 20.4017 45.4988 19.9931 46.1123C19.5844 46.7259 19.0027 47.2044 18.3218 47.4872C17.6409 47.77 16.8915 47.8444 16.1683 47.7009C15.4451 47.5574 14.7807 47.2025 14.2594 46.6812C13.7381 46.1599 13.3832 45.4955 13.2397 44.7723C13.0962 44.0492 13.1706 43.2997 13.4534 42.6188C13.7362 41.9379 14.2147 41.3562 14.8283 40.9475C15.4419 40.5388 16.163 40.3213 16.9003 40.3228L16.8991 40.9235V40.3228C16.8995 40.3228 16.8999 40.3228 16.9003 40.3228C17.8861 40.3231 18.8315 40.7148 19.5287 41.412C20.2259 42.1092 20.6177 43.0548 20.6178 44.0409Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8372 27.346C14.4437 27.3451 14.0588 27.461 13.7312 27.6789C13.4036 27.8969 13.148 28.2071 12.9968 28.5704C12.8456 28.9336 12.8055 29.3336 12.8817 29.7196C12.9579 30.1057 13.147 30.4604 13.4249 30.739C13.7028 31.0175 14.0571 31.2074 14.443 31.2845C14.8288 31.3616 15.2288 31.3225 15.5925 31.1721C15.9561 31.0217 16.2669 30.7669 16.4856 30.4398C16.7043 30.1126 16.8211 29.728 16.8211 29.3345L17.4218 29.3345L16.8211 29.3332C16.8217 29.0724 16.7709 28.8141 16.6715 28.5731C16.5721 28.332 16.4262 28.1129 16.2421 27.9283C16.0579 27.7437 15.8392 27.5973 15.5983 27.4974C15.3575 27.3975 15.0993 27.346 14.8386 27.346L14.8372 27.346ZM18.0226 29.3352C18.0225 29.9663 17.8352 30.583 17.4845 31.1076C17.1336 31.6323 16.635 32.0412 16.0516 32.2824C15.4683 32.5236 14.8265 32.5864 14.2075 32.4627C13.5885 32.339 13.0201 32.0345 12.5743 31.5876C12.1284 31.1407 11.8252 30.5716 11.703 29.9523C11.5807 29.333 11.645 28.6914 11.8875 28.1086C12.1301 27.5259 12.5401 27.0282 13.0657 26.6785C13.5911 26.329 14.2083 26.1432 14.8393 26.1445C15.2578 26.1446 15.6722 26.2272 16.0588 26.3876C16.4455 26.5481 16.7969 26.7832 17.0926 27.0797C17.3884 27.3762 17.6228 27.7281 17.7824 28.1152C17.9419 28.5021 18.0235 28.9167 18.0226 29.3352ZM14.8393 26.1445C14.8395 26.1445 14.8398 26.1445 14.84 26.1445L14.8386 26.7453L14.8386 26.1445C14.8388 26.1445 14.8391 26.1445 14.8393 26.1445Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.691 39.7459C30.2975 39.745 29.9126 39.8609 29.585 40.0788C29.2573 40.2968 29.0018 40.607 28.8505 40.9703C28.6993 41.3335 28.6593 41.7335 28.7355 42.1195C28.8117 42.5056 29.0007 42.8603 29.2786 43.1389C29.5566 43.4174 29.9109 43.6073 30.2967 43.6844C30.6826 43.7615 31.0826 43.7224 31.4463 43.572C31.8099 43.4216 32.1207 43.1668 32.3394 42.8397C32.5581 42.5125 32.6749 42.1279 32.6749 41.7344L33.2756 41.7344L32.6749 41.7331C32.6755 41.4723 32.6246 41.214 32.5253 40.973C32.4259 40.7319 32.28 40.5128 32.0958 40.3282C31.9117 40.1436 31.6929 39.9972 31.4521 39.8973C31.2113 39.7974 30.9531 39.746 30.6924 39.746L30.691 39.7459ZM33.8764 41.7351C33.8762 42.3662 33.6889 42.9829 33.3382 43.5075C32.9874 44.0322 32.4887 44.4411 31.9054 44.6823C31.3221 44.9235 30.6803 44.9863 30.0613 44.8626C29.4423 44.7389 28.8739 44.4344 28.4281 43.9875C27.9822 43.5406 27.679 42.9715 27.5567 42.3522C27.4345 41.7329 27.4987 41.0913 27.7413 40.5085C27.9839 39.9258 28.3939 39.4281 28.9195 39.0785C29.4448 38.7289 30.0621 38.5431 30.6931 38.5444C31.1116 38.5445 31.526 38.6271 31.9125 38.7875C32.2993 38.948 32.6506 39.1832 32.9464 39.4796C33.2422 39.7761 33.4765 40.128 33.6361 40.5151C33.7956 40.902 33.8773 41.3166 33.8764 41.7351ZM30.6931 38.5444C30.6933 38.5444 30.6935 38.5444 30.6938 38.5444L30.6924 39.1452L30.6924 38.5444C30.6926 38.5444 30.6928 38.5444 30.6931 38.5444Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.4294 0.600755C23.4294 0.268967 23.6984 0 24.0302 0H33.2758C33.6076 0 33.8766 0.268967 33.8766 0.600755V8.49469C33.8766 8.82648 33.6076 9.09544 33.2758 9.09544H24.631V27.6648C24.631 27.9966 24.362 28.2655 24.0302 28.2655C23.6984 28.2655 23.4294 27.9966 23.4294 27.6648V0.600755ZM24.631 7.89393H32.6751V1.20151H24.631V7.89393Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.0419 2.30095C33.0419 1.96916 33.3109 1.7002 33.6427 1.7002H37.5476C37.8794 1.7002 38.1483 1.96916 38.1483 2.30095V10.7416C38.1483 11.0733 37.8794 11.3423 37.5476 11.3423H29.2511C28.9194 11.3423 28.6504 11.0733 28.6504 10.7416V8.77106C28.6504 8.43927 28.9194 8.17031 29.2511 8.17031C29.5829 8.17031 29.8519 8.43927 29.8519 8.77106V10.1408H36.9468V2.90171H33.6427C33.3109 2.90171 33.0419 2.63274 33.0419 2.30095Z"
            fill="white"
          />
        </svg>
      </div>
      <h2 className="font-bold text-[20px] leading-[28px]">No results found</h2>
      <p className="text-center mt-[8px] text-[#C8C8C8] text-[14px] leading-[20px]">
        Try changing your search by changing categories.
      </p>
    </div>
  );
};

export default ProjectList;
