import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Upload, UploadApiError } from "upload-js";

import { normalizeObjectKeys } from "../utils";

import ProjectList from "../components/ProjectList";

export interface ProjectAttributes {
  name: string;
  categories: string[];
  audit_1?: string;
  audit_2?: string;
  defilama_link?: string;
  deploy: string;
  email: string;
  live: string;
  logo: string;
  mobile_version: string;
  presentation_screens: string;
  project_description: string;
  project_name: string;
  project_official_website: string;
  show_on_website: string;
}

export interface ProjectInterface {
  createdTime: string;
  fields: ProjectAttributes;
  id: string;
}

const getAllCategories = (projects: ProjectInterface[]) => {
  const categories = new Set<string>();
  projects.map((p) => {
    p.fields.categories.map((c) => c && categories.add(c));
  });
  return [...categories.values()];
};

const getAllDeploys = (projects: ProjectInterface[]) => {
  const deploys = new Set<string>();
  projects.map((p) => {
    deploys.add(p.fields.deploy);
  });
  return [...deploys.values()];
};

const Airtable = () => {
  const upload = Upload({ apiKey: "free" });
  const [uploadFileList, setUploadFileList] = useState<FileList | null>(null);

  const uploadFiles = () => {
    if (!uploadFileList || uploadFileList.length === 0) {
      return;
    }

    Array.from(uploadFileList).map((file) =>
      upload
        .uploadFile(file, {
          onBegin: () => console.log("beginning upload"),
          onProgress: (status) => console.log(status),
        })
        .then((e) => console.log(e))
    );
  };

  const [projectData, setProjectData] = useState<ProjectInterface[] | null>(
    null
  );
  const [filteredProjects, setFilteredProjects] = useState<
    ProjectInterface[] | null
  >(null);
  const [currentProject, setCurrentProject] = useState<ProjectInterface | null>(
    null
  );

  const [categories, setCategories] = useState<string[] | null>(null);
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [deploys, setDeploys] = useState<string[] | null>(null);
  const [currentDeploy, setCurrentDeploy] = useState<string | null>(null);

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const [formProjectName, setFormProjectName] = useState<string>();

  useEffect(() => {
    fetch(`/api/getProjects`)
      .then((res) => res.json())
      .then((data) => {
        setProjectData(normalizeObjectKeys(data));
        setCategories(getAllCategories(normalizeObjectKeys(data)));
        setDeploys(getAllDeploys(normalizeObjectKeys(data)));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!!currentDeploy || !deploys) {
      return;
    }

    setCurrentDeploy(deploys[0]);
  }, [deploys]);

  useEffect(() => {
    if (!projectData) {
      return;
    }

    setFilteredProjects(
      projectData
        .filter((p) =>
          currentDeploy ? p.fields.deploy === currentDeploy : true
        )
        .filter((p) =>
          currentCategory
            ? p.fields.categories.indexOf(currentCategory) !== -1
            : true
        )
    );
  }, [currentDeploy, currentCategory]);

  const handleProjectClick = (id: string) => {
    const matchingProject = filteredProjects?.find((p) => p.id === id);

    if (matchingProject) {
      setCurrentProject(matchingProject);
      setDetailModalOpen(true);
    }
  };

  const handlePreviousProjectClick = () => {
    if (!currentProject || !filteredProjects) {
      return;
    }

    const currentProjectPosition = filteredProjects?.indexOf(currentProject);

    if (filteredProjects[currentProjectPosition - 1]) {
      setCurrentProject(filteredProjects[currentProjectPosition - 1]);
    }
  };

  const handleNextProjectClick = () => {
    if (!currentProject || !filteredProjects) {
      return;
    }

    const currentProjectPosition = filteredProjects?.indexOf(currentProject);

    if (filteredProjects[currentProjectPosition + 1]) {
      setCurrentProject(filteredProjects[currentProjectPosition + 1]);
    }
  };

  const hasPreviousProject = () => {
    if (!currentProject || !filteredProjects) {
      return false;
    }

    const currentProjectPosition = filteredProjects?.indexOf(currentProject);

    return !!filteredProjects[currentProjectPosition - 1];
  };

  const hasNextProject = () => {
    if (!currentProject || !filteredProjects) {
      return false;
    }

    const currentProjectPosition = filteredProjects?.indexOf(currentProject);

    return !!filteredProjects[currentProjectPosition + 1];
  };

  const handleProjectFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fetch(`/api/newProject`, {
      method: "POST",
      body: JSON.stringify({
        project_name: formProjectName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const modalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#171717",
      border: "none",
      padding: "24px",
      color: "white",
      width: "80vw",
      minHeight: "600px",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.75)",
    },
  };

  if (!filteredProjects) {
    return null;
  }

  return (
    <main className="bg-black text-white pt-[80px] pb-[80px] min-h-screen">
      <div className="max-w-[960px] px-[24px] mx-auto flex">
        <div className="w-[200px]">
          <div className="bg-grey-dark rounded-[12px] flex">
            {deploys &&
              deploys.map((d) => (
                <button
                  key={d}
                  onClick={() => {
                    setCurrentDeploy(d);
                    setCurrentCategory(null);
                  }}
                  className={classNames(
                    "px-[8px]",
                    "py-[12px]",
                    "flex-1",
                    "first-of-type:rounded-tl-[12px]",
                    "first-of-type:rounded-bl-[12px]",
                    "last-of-type:rounded-tr-[12px]",
                    "last-of-type:rounded-br-[12px]",
                    d === currentDeploy
                      ? "bg-orange text-white"
                      : "hover:bg-grey hover:text-black"
                  )}
                >
                  {d}
                </button>
              ))}
          </div>
          <button
            onClick={() => setCurrentCategory(null)}
            className={classNames(
              "bg-grey-dark",
              "rounded-[12px]",
              "text-center",
              "px-[8px]",
              "py-[8px]",
              "block",
              "w-full",
              "hover:bg-orange",
              "hover:text-white",
              "cursor-pointer",
              "mt-[16px]",
              !currentCategory && "bg-orange text-white"
            )}
          >
            All categories
          </button>
          <div className="mt-[16px] flex flex-col gap-[12px]">
            {categories &&
              categories.map((c) => (
                <button
                  key={c}
                  onClick={() => setCurrentCategory(c)}
                  className={classNames(
                    "bg-grey-dark",
                    "rounded-[4px]",
                    "text-center",
                    "px-[8px]",
                    "py-[4px]",
                    "block",
                    "w-full",
                    "hover:bg-orange",
                    "hover:text-white",
                    "cursor-pointer",
                    c === currentCategory && "bg-orange text-white"
                  )}
                >
                  {c}
                </button>
              ))}
          </div>
        </div>
        <div className="flex-1 pl-[24px]">
          <ProjectList
            projects={filteredProjects}
            onProjectClick={handleProjectClick}
          />
          <button
            onClick={() => setSubmitModalOpen(true)}
            className={classNames(
              "bg-grey-dark",
              "rounded-[12px]",
              "text-center",
              "px-[8px]",
              "py-[8px]",
              "block",
              "w-full",
              "hover:bg-orange",
              "hover:text-white",
              "cursor-pointer",
              "mt-[16px]"
            )}
          >
            Submit new project
          </button>
        </div>
        <ReactModal
          ariaHideApp={false}
          isOpen={detailModalOpen}
          onRequestClose={() => setDetailModalOpen(false)}
          style={modalStyles}
          contentLabel="Project detail modal"
        >
          <div className="flex items-center justify-between mb-[16px]">
            <button
              onClick={() => handlePreviousProjectClick()}
              disabled={!hasPreviousProject()}
              className="text-white disabled:text-grey-dark"
            >
              previous project
            </button>
            <button
              onClick={() => handleNextProjectClick()}
              disabled={!hasNextProject()}
              className="text-white disabled:text-grey-dark"
            >
              next project
            </button>
          </div>
          <h3 className="text-[24px] leading-7 font-bold">
            {currentProject?.fields.project_name}
          </h3>
          <p className="mt-[8px]">{currentProject?.id}</p>
          <p className="mt-[8px]">
            {currentProject?.fields.project_description}
          </p>
          <img
            src={currentProject?.fields.presentation_screens}
            alt=""
            className="mt-[24px]"
          />
        </ReactModal>
        <ReactModal
          ariaHideApp={false}
          isOpen={submitModalOpen}
          onRequestClose={() => setSubmitModalOpen(false)}
          style={modalStyles}
          contentLabel="Add new project modal"
        >
          <div className="flex flex-col items-center">
            <h3 className="text-[24px]">Submit a new project</h3>
            <form
              onSubmit={handleProjectFormSubmit}
              className="mt-[16px] flex flex-col items-center w-[400px]"
            >
              <label className="w-full">
                <span className="uppercase text-[14px] text-grey block mb-[4px] w-full">
                  Project name
                </span>
                <input
                  type="text"
                  placeholder="Project name"
                  value={formProjectName}
                  onChange={(e) => setFormProjectName(e.target.value)}
                  className="w-full px-[12px] py-[10px] text-black bg-white border-2 border-transparent focus:border-orange outline-none shadow-none"
                />
              </label>
              <input
                type="file"
                onChange={(e) => {
                  console.log(e.target.files);
                  setUploadFileList(e.target.files);
                }}
                multiple
              />
              <div
                className="bg-white px-[12px] py-[12px] text-black cursor-pointer"
                onClick={() => uploadFiles()}
              >
                upload
              </div>
              <button
                type="submit"
                className={classNames(
                  "bg-grey-dark",
                  "rounded-[12px]",
                  "text-center",
                  "px-[8px]",
                  "py-[8px]",
                  "block",
                  "w-full",
                  "hover:bg-orange",
                  "hover:text-white",
                  "cursor-pointer",
                  "mt-[16px]"
                )}
              >
                Submit
              </button>
            </form>
          </div>
        </ReactModal>
      </div>
    </main>
  );
};

export default Airtable;
