import classNames from "classnames";
import React from "react";

import { ProjectInterface } from "../pages/airtable";

export interface ProjectProps extends ProjectInterface {
  onProjectClick?: (id: string) => void;
}

const Project = ({ fields, id, onProjectClick }: ProjectProps) => {
  return (
    <li
      className={classNames(
        "bg-grey-dark",
        "p-[16px]",
        "rounded-[12px]",
        "flex",
        "gap-[24px]",
        "border border-transparent",
        onProjectClick && "cursor-pointer hover:border-orange"
      )}
      onClick={() => onProjectClick?.(id)}
    >
      {fields.logo && (
        <div className="w-[80px] h-[80px]">
          <img
            src={fields.logo}
            alt={`Logo for project ${fields.project_name}`}
            className="object-cover w-[80px] h-[80px]"
          />
        </div>
      )}
      <div className="flex-1 flex flex-col items-start">
        <h2 className="font-bold text-24">{fields.project_name}</h2>
        <p className="mt-[4px]">{fields.project_description}</p>
        <code className="text-[14px] mt-[4px]">
          {fields.categories.join(", ")}
        </code>
      </div>
    </li>
  );
};

export default Project;
